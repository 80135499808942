import React, {useEffect, useState} from 'react';
import {useQueryLiveRoomMutation} from '../Services/allOthersApi';
import {useParams} from 'react-router-dom';
import {ToolTip} from '../Components/base/tooltip/tooltip';
import {useGetMeetingQuery} from '../Services/meetingApi';
import BeeMG from '../Assets/Images/logo.svg';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '../Components/base/modal/modal';
import Button from '../Components/base/button/button';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import '@videojs/http-streaming';
import Player from 'video.js/dist/types/player';
import {t} from 'i18next';
import LiveStreaming1 from '../Assets/Images/LiveStreaming1.png';
import obs_application from '../Assets/Images/obs_application.png';
import obs_setting from '../Assets/Images/obs_setting.png';
import obs_select_stream from '../Assets/Images/obs_select_stream.png';
import obs_keys from '../Assets/Images/obs_keys.png';
import obs_addSource  from '../Assets/Images/obs_addSource.png';
import obs_media_name from '../Assets/Images/obs_media_name.png';
import obs_select_path from '../Assets/Images/obs_select_path.png';
import obs_startstream from '../Assets/Images/obs_startstream.png';

export default function HostPage() {
  const {meetingId} = useParams();
  const [queryLiveRoom, {isLoading, isError, error, data, isSuccess}] =
    useQueryLiveRoomMutation();
  const {data: meetingData} = useGetMeetingQuery(meetingId ?? '');

  const [secretKey, setSecretKey] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [viewStream, setViewStream] = useState(false);
  const [player, setPlayer] = useState<Player | null>(null);
  const [server, setServer] = useState<string | null>(null);

  useEffect(() => {
    if (data) {
      setSecretKey(data.data.keySecret);
      setServer(data.data.server);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (viewStream && meetingData?.streamLink) {
      const videoPlayer = videojs('video-player', {
        autoplay: true,
        controls: true,
        preload: 'auto',
        techOrder: ['html5'],
        sources: [
          {
            src: meetingData.streamLink,
            type: 'application/vnd.apple.mpegurl',
          },
        ],
      });
      setPlayer(videoPlayer);

      return () => {
        if (videoPlayer) {
          videoPlayer.dispose();
        }
      };
    }
  }, [viewStream, meetingData]);

  return (
    <>
      <div className="flex flex-col w-full h-full p-6 bg-gray-50">
        <div className="flex flex-col md:flex-row items-center justify-center w-full">
          <div className="flex flex-col items-center md:w-1/3 space-y-4">
            <img
              src={BeeMG}
              alt="Movie Poster"
              className="rounded-lg shadow-lg w-72 h-112"
            />
          </div>

          <div className="flex flex-col justify-center items-start md:w-1/2 space-y-6 md:pl-8">
            <h1 className="text-4xl font-bold text-gray-800 text-center md:text-left mt-2">
              {meetingData?.meetingDetails?.title}
            </h1>
            <div className="text-center md:text-left">
              <p className="text-lg font-semibold text-gray-700">
                {t('secretKey')}:
                <span className="text-blue-500 ml-2">
                  {secretKey || '************'}
                </span>
              </p>
              <p className="text-lg font-semibold text-gray-700">
                {t('streamLink')}:
                <span className="text-blue-500 ml-2">
                  {meetingData?.streamLink || '************'}
                </span>
              </p>
              <p className="text-lg font-semibold text-gray-700">
                {t('server')}:
                <span className="text-blue-500 ml-2">
                  {server || '************'}
                </span>
              </p>
            </div>

            <div className="flex items-center space-x-3">
              <ToolTip tip="Click here to view the secret key">
                <button
                  className="bg-gray-500 hover:bg-yellow-500 text-white font-bold py-2 px-6 rounded shadow-md transform transition-transform hover:scale-105"
                  onClick={() => queryLiveRoom({id: meetingId ?? ''} as any)}>
                  {t('viewSecretKey')}
                </button>
              </ToolTip>

              <ToolTip tip="Click here for streaming setup">
                <button
                  className="bg-gray-500 hover:bg-yellow-500 text-white font-bold py-2 px-6 rounded shadow-md transform transition-transform hover:scale-105"
                  onClick={() => setIsModalVisible(true)}>
                  Help
                </button>
              </ToolTip>

              {meetingData?.streamLink && (
                <ToolTip tip="Click here to view the stream">
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded shadow-md transform transition-transform hover:scale-105"
                    onClick={() => setViewStream(true)}>
                    {t('viewStream')}
                  </button>
                </ToolTip>
              )}
            </div>
          </div>
        </div>

        {viewStream && (
          <div className="mt-6 w-full bg-black p-4 rounded-md shadow-lg">
            <div
              className="h-[400px] w-full rounded-md md:rounded-lg overflow-hidden">
              <video
                id="video-player"
                className="video-js vjs-default-skin w-full h-full object-cover"
              />
            </div>
          </div>
        )}
      </div>

      <Modal
        position="large"
        isOpen={isModalVisible}
        toggle={() => setIsModalVisible(false)}
        closeOnClickOutside={false}>
        <ModalCloseButton toggle={() => setIsModalVisible(false)} />
        <ModalHeader>{t('stepsToBeFollowedForStreamingSetup')}</ModalHeader>
        <div className="max-h-96 overflow-y-auto">
        <ModalBody>
          <ol className="list-decimal ml-4 space-y-4">
            <div className="cursor-pointer flex flex-col items-center justify-center mt-2">
              <img
                src={LiveStreaming1}
                alt="Way 1 to join the meeting"
                className="w-42 h-42 h-auto rounded-md p-2"
              />
              <p className="text-sm mt-2">1. Copy the server and secret key </p>
            </div>

            <div className="cursor-pointer flex flex-col items-center justify-center mt-2">
              <img
                src={obs_application}
                alt="Way 1 to join the meeting"
                className="w-42 h-auto rounded-md p-2"
              />
              <p className="text-sm mt-2">2. open obs studio </p>
            </div>

            <div className="cursor-pointer flex flex-col items-center justify-center mt-2">
              <img
                src={obs_setting}
                alt="Way 1 to join the meeting"
                className="w-42 h-auto rounded-md p-2"
              />
              <p className="text-sm mt-2">3. select setting</p>
            </div>

            <div className="cursor-pointer flex flex-col items-center justify-center mt-2">
              <img
                src={obs_select_stream}
                alt="Way 1 to join the meeting"
                className="w-42 h-auto rounded-md p-2"
              />
              <p className="text-sm mt-2">4. select stream </p>
            </div>

            <div className="cursor-pointer flex flex-col items-center justify-center mt-2">
              <img
                src={obs_keys}
                alt="Way 1 to join the meeting"
                className="w-42 h-auto rounded-md p-2"
              />
              <p className="text-sm mt-2">5. paste the secret key </p>
            </div>

            <div className="cursor-pointer flex flex-col items-center justify-center mt-2">
              <img
                src={obs_addSource}
                alt="Way 1 to join the meeting"
                className="w-42 h-auto rounded-md p-2"
              />
              <p className="text-sm mt-2">6. click add source and click media source </p>
            </div>

            <div className="cursor-pointer flex flex-col items-center justify-center mt-2">
              <img
                src={obs_media_name}
                alt="Way 1 to join the meeting"
                className="w-42 h-auto rounded-md p-2"
              />
              <p className="text-sm mt-2">7.  Enter media name </p>
            </div>

            <div className="cursor-pointer flex flex-col items-center justify-center mt-2">
              <img
                src={obs_select_path}
                alt="Way 1 to join the meeting"
                className="w-42 h-auto rounded-md p-2"
              />
              <p className="text-sm mt-2">8. select your path from your device </p>
            </div>

            <div className="cursor-pointer flex flex-col items-center justify-center mt-2">
              <img
                src={obs_startstream}
                alt="Way 1 to join the meeting"
                className="w-42 h-auto rounded-md p-2"
              />
              <p className="text-sm mt-2">9. click on start stream </p>
            </div>
            
          </ol>
        </ModalBody>
        </div>
        <ModalFooter>
          <Button
            id="btn_ok"
            onClick={() => setIsModalVisible(false)}
            color="footerButton">
            {t('ok')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
