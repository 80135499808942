import { useState, useEffect } from "react";
import BeeMG from "../Assets/Images/logo.svg";
import useToggle from "../Components/_utils/useToggle";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import '@videojs/http-streaming';
import Player from "video.js/dist/types/player";
import { useParams } from "react-router-dom";
import { useGetMeetingQuery } from "../Services/meetingApi";
import { useAppSelector } from "../Store/hooks";
import { getCurrentUser } from "../Services/userReducer";

const MoviePage = () => {
  const { meetingId } = useParams()

  const [viewVideo, setViewVideo] = useState(false);
  const { toggle, isOpen } = useToggle();

  const currentUser = useAppSelector(getCurrentUser);
  const {data: meetingData, isLoading, isError, error} = useGetMeetingQuery(meetingId ?? '');



  const video = "https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8"; // HLS stream link
  const [seeVideo, setSeeVideo] = useState<any>(video);

  const handleButtonClick = () => {
    if(meetingData?.meetingParticipant[0]?.user?.id === currentUser.id){
      setViewVideo(true);
      setSeeVideo(meetingData.streamLink);
    }
  };

  // Initialize Video.js Player
  useEffect(() => {
    let player: Player;
    if (viewVideo) {
      // Initialize Video.js when video view is shown
      player = videojs('video-player', {
        autoplay: true,
        controls: true,
        preload: 'auto',
        techOrder: ['html5'],
        sources: [
          {
            src: seeVideo,
            type: 'application/vnd.apple.mpegurl',
          }
        ]
      });

      return () => {
        if (player) {
          player.dispose();
        }
      };
    }
  }, [viewVideo]);

  return (
    <div className="flex flex-col w-full min-h-screen bg-gray-100 p-4">
      {!viewVideo ? (
        <>
        <div className="flex justify-center items-center">
          <div className="w-3/4  bg-white-900 text-black p-6 rounded-lg flex flex-col md:flex-row">
            <div className="w-full md:w-1/3 flex justify-center md:justify-end items-center md:items-center">
              <img
                src={BeeMG}
                alt="Movie Poster"
                className="rounded-lg shadow-lg bg-white w-[300px] h-[450px]"
              />
            </div>

            <div className="w-full md:w-2/3 flex flex-col justify-center mt-4 md:mt-0 md:ml-6 px-4 text-center md:text-left">
              <h1 className="text-3xl font-bold mb-2">{meetingData?.meetingDetails?.title}</h1>
              <div className="text-lg mb-2">
                <span>2h 30m</span>
                <span className="mx-2">|</span>
                <span>{meetingData?.language}</span>
              </div>

              <div className="text-lg">
                <span>
                    {meetingData?.meetingDetails?.categories?.join(' | ') || 'No Categories'}
                  </span>
                <span className="mx-2">|</span>
                <span className="bg-BeeMG-yellow text-black px-2 py-1 rounded">
                  U/A
                </span>
              </div>

              <div className="mt-10 flex justify-center md:justify-start">
                {/* <button
                  className={`${
                    meetingData?.meetingParticipant[0]?.user?.id === currentUser.id
                      ? "bg-blue-500 text-white px-4 py-3 rounded-lg shadow-md border-2 border-blue-700 hover:bg-blue-700"
                      : "bg-red-500 text-white px-4 py-3 rounded-lg shadow-md hover:text-gray-500"
                  } text-lg`}
                  onClick={handleButtonClick}
                 >
                {meetingData?.meetingParticipant[0]?.user?.id === currentUser.id ? "View Show" : "Waiting"}
                </button> */} 
                  <button
                    className={`px-4 py-2 rounded  text-black text-md ${meetingData?.meetingParticipant[0]?.user?.id === currentUser.id
                        ? "bg-BeeMG-yellow  hover:bg-BeeMG-light-gray cursor-pointer"
                        : "bg-BeeMG-yellow  hover:bg-BeeMG-light-gray cursor-not-allowed "
                      }`}
                    disabled={meetingData?.meetingParticipant[0]?.user?.id !== currentUser.id}
                    onClick={handleButtonClick}
                  >
                    {meetingData?.meetingParticipant[0]?.user?.id === currentUser.id ? "View Show" : "Waiting"}
                  </button>
              </div>
            </div>
          </div>
          </div>

          <div className="mt-6 p-4 bg-white rounded-lg">
            <h2 className="text-xl font-bold mb-2">Description</h2>
            <p
              className="mt-4 max-h-24 overflow-y-auto scrollbar-thin p-2"
              dangerouslySetInnerHTML={{
                __html: meetingData?.meetingDetails?.description || "No description available.",
              }}
            ></p>
                
          </div>
          
        </> 
      ) : (
        <div className="flex flex-col items-center justify-center w-full h-screen bg-black">
          {/* Video Section */}
         <div
         className="h-[80vh] w-full rounded-md md:rounded-lg overflow-hidden"
          >
            <video
              id="video-player"
              className="video-js vjs-default-skin w-full h-full object-cover"
            >
              {/* Video source will be set dynamically by Video.js */}
            </video>
          </div>
          {/* <button
            className="mt-4 bg-gray-800 text-white px-4 py-2 rounded hover:bg-gray-600"
            onClick={() => setViewVideo(false)}
          >
            Back to Movie Details
          </button> */}
        </div>
      )}
    </div>
  );
};

export default MoviePage;

